import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import { createPinia } from 'pinia'

import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/store";
const pinia = createPinia()


import BootstrapVue3 from 'bootstrap-vue-3';

import VueFeather from 'vue-feather';

import '@/assets/scss/config/default/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
    faUsersLine,
    faBullhorn,
    faChartSimple,
    faMagnifyingGlass,
    faLocationArrow,
    faLayerGroup,
    faLink,
    faMapLocation,
    faBuildingColumns,
    faTreeCity,
    faCaretRight,
    faCaretUp,
    faCaretDown,
    faXmark,
    faLocationCrosshairs,
    faFile,
    faLocationDot,
    faCity
} from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(
    faUsersLine, 
    faBullhorn, 
    faChartSimple, 
    faMagnifyingGlass,
    faLocationArrow,
    faLayerGroup,
    faLink,
    faMapLocation,
    faBuildingColumns,
    faTreeCity,
    faCity,
    faCaretRight,
    faCaretUp,
    faCaretDown,
    faXmark,
    faLocationCrosshairs,
    faFile,
    faLocationDot
)

// FakeBackend authentication
// import { configureFakeBackend } from './helpers/fake-backend';
// configureFakeBackend();


// Firebase authentication
// import { initFirebaseBackend } from './authUtils';

// const firebaseConfig = {
//     apiKey: process.env.VUE_APP_APIKEY,
//     authDomain: process.env.VUE_APP_AUTHDOMAIN,
//     databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
//     projectId: process.env.VUE_APP_PROJECTId,
//     storageBucket: process.env.VUE_APP_STORAGEBUCKET,
//     messagingSenderId: process.env.VUE_APP_MEASUREMENTID
// };

// initFirebaseBackend(firebaseConfig);

import { callOptionsMap } from './common/map/function_map';

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});
async function initApp() {
    await callOptionsMap();
    createApp(App)
        .use(store)
        .use(router)
        .use(BootstrapVue3)
        .use(pinia)
        .component(VueFeather.type, VueFeather)
        .component('font-awesome-icon', FontAwesomeIcon)
        .use(i18n).mount('#app');
}
initApp()
