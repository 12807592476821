const ROUTERMAPCONTROL = [
  
    {
        path: "/ban-do/so-sanh-thong-tin",
        name: "MapCompareMap",
        meta: {
          title: "So sánh bản đồ",
        },
        component: () => import("../views/map/main_map/ComperaMap.vue"),
      },
];

export default ROUTERMAPCONTROL;