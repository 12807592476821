// import store from "@/state/store";
import ROUTERMAPCONTROL from "./map_control.js";

export default [
  ...ROUTERMAPCONTROL,
  {
    path: "/",
    name: "HomePage",
    meta: { title: "Trang chủ" },
    component: () => import("../views/home/HomePage.vue"),
  },
  {
    path: "/gioi-thieu/ban-do-so",
    name: "Map4dIntroPage",
    meta: { title: "Giới thiệu nền tảng bản đồ số" },
    component: () => import("../views/introduce/Map4dIntroduce.vue"),
  },
  {
    path: "/gioi-thieu/phan-mem-quy-hoach",
    name: "ZoningIntroPage",
    meta: { title: "Giới thiệu phần mềm quy hoạch" },
    component: () => import("../views/introduce/PhanMemQuyHoach.vue"),
  },
  {
    path: "/gioi-thieu/ve-chung-toi",
    name: "AboutUsPage",
    meta: { title: "Về chúng tôi" },
    component: () => import("../views/introduce/AboutUs.vue"),
  },
  {
    path: "/lien-he",
    name: "ContactPage",
    meta: { title: "Liên hệ" },
    component: () => import("../views/contact/Contact.vue"),
  },
  {
    path: "/ban-do",
    name: "MapPortal",
    meta: {
      title: "Bản đồ công khai",
    },
    component: () => import("../views/map/map_portal/MapPortal.vue"),
  },
  {
    path: "/y-kien-cong-dong",
    name: "Consultation",
    meta: { title: "Xin ý kiến cộng đồng" },
    component: () =>
      import("../views/infomation/consultation/Consultation.vue"),
  },
  {
    path: "/apps",
    name: "AppsPage",
    meta: { title: "Ứng dụng" },
    component: () =>
      import("../views/applications/AppLayout.vue"),
  },
  {
    path: "/quan-ly-ho-so",
    name: "DataManager",
    meta: { title: "Quản lý hồ sơ" },
    component: () =>
      import("../views/data-manager/ManagerProfile.vue"),
  },
];
